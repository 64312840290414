.homeMain {
  height: 100vh;
  background: #e5e5e5;
}
.mainContent {
  margin-left: 250px;
}
.jss7 {
  /* background: #e5e5e5; */
  min-height: calc(100vh - 65px);
}
.headerWrap {
  background: white;
  padding-top: 8px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
}
button:focus {
  outline: none;
  outline: none;
}

@media (max-width: 320px) {
  .mainContent {
    margin-left: 0px;
  }
  .headerWrap {
    width: 100%;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .mainContent {
    margin-left: 0px;
  }
  .headerWrap {
    width: 100%;
  }
}

@media (min-width: 361px) and (max-width: 767.98px) {
  .makeStyles-drawerPaper-6 {
    display: none;
  }
  .mainContent {
    margin-left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
}

@media (min-width: 1200px) {
}
