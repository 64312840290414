.projectStatus {
  width: 66px;
  height: 25px;
  background: #5fdba7;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 3px;
  color: #ffffff;
}
.projectStatusOnHold {
  width: 66px;
  height: 25px;
  background: #f26565;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 3px;
  color: #ffffff;
}
.projectStatusCompleted {
  width: 90px;
  height: 25px;
  background: #000000;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 3px;
  color: #ffffff;
}
.projectStage {
  width: 116px;
  height: 25px;
  background: #5fdba7;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 3px;
  color: #ffffff;
}
.projectStageDispatch {
  width: 116px;
  height: 25px;
  background: #df8c35;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 3px;
  color: #ffffff;
}

.searchHeadTextWrap {
  width: 100%;
  height: 100%;
  background: #cbc6c6;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  padding: 5px;
  position: relative;
}
.searchHeadText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0.01em;
  color: #000000;
}
