.profileMain {
  margin-top: 0px;
}

.profileCard {
  width: 800px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: -120px;
}

.optionalText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #c4c4c4;
}

.noteText {
  width: 475px;
  height: 96px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;
  resize: none;
}

.inpBoxNote {
  background: #069aa7;
  border-radius: 4px 0px 0px 4px;
  width: 8px;
  height: 96px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.noteOptional {
  margin-left: 363px;
}

.updateProfile {
  background: #069aa7;
  box-shadow: 0px 4px 4px rgba(16, 156, 241, 0.24);
  border-radius: 4px;
  width: 485px;
  height: 48px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  border: none;
}

.inputLeftProfile {
  margin-right: 250px;
}
#code {
  background: #f2f2f2;
}

@media (max-width: 320px) {
  .profileCard {
    width: 280px;
    height: 100vh;
  }
  .mobileImage {
    display: none;
  }
  .profileMain {
    margin-top: 150px;
  }
  .inputLeftProfile {
    margin-right: 28px;
  }
  .updateProfile {
    width: 230px;
    height: 36px;
    margin-left: -30px;
  }
  .formMainProfile {
    margin-left: 20px;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .profileCard {
    width: 280px;
    height: 100vh;
  }
  .mobileImage {
    display: none;
  }
  .profileMain {
    margin-top: 150px;
  }
  .inputLeftProfile {
    margin-right: 28px;
  }
  .updateProfile {
    width: 226px;
    height: 36px;
  }
  .formMainProfile {
    margin-left: 20px;
  }
}

@media (min-width: 361px) and (max-width: 767.98px) {
  .profileCard {
    width: 280px;
    height: 100%;
  }
  .mobileImage {
    display: none;
  }
  .profileMain {
    margin-top: 150px;
  }
  .inputLeftProfile {
    margin-right: 28px;
  }
  .updateProfile {
    width: 230px;
    height: 36px;
    margin-left: -30px;
  }
  .formMainProfile {
    margin-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .profileCard {
    width: 480px;
    height: 100vh;
  }
  .mobileImage {
    display: none;
  }
  .profileMain {
    margin-top: 150px;
  }
  .inputLeftProfile {
    margin-right: 125px;
  }
  .updateProfile {
    width: 226px;
    height: 36px;
  }
  .formMainProfile {
    margin-left: 120px;
  }
  .profileButtonLeft {
    margin-right: 125px;
  }
}

@media (min-width: 1200px) {
}
