.submitLeadMain {
  margin-top: 0px;
}

.SubmitLeadCard {
  width: 800px;
  /* height: calc(100vh - 150px); */
  height: 700px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: -120px;
}

.optionalText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #c4c4c4;
}

.noteText {
  width: 475px;
  height: 96px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;
  resize: none;
}

.inpBoxNote {
  background: #069aa7;
  border-radius: 4px 0px 0px 4px;
  width: 8px;
  height: 96px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.noteOptional {
  margin-left: 363px;
}

.leadSubmit {
  background: #069aa7;
  box-shadow: 0px 4px 4px rgba(16, 156, 241, 0.24);
  border-radius: 4px;
  width: 485px;
  height: 48px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  border: none;
}

.blueBar {
  width: 100%;
  height: 20px;
  background: #069aa7;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.submitSuccess {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 150%;
  color: #000000;
}
.submitSuccessExtra {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}

.doneBtn {
  background: #069aa7;
  box-shadow: 0px 4px 4px rgba(16, 156, 241, 0.24);
  border-radius: 4px;
  width: 160px;
  height: 42px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  border: none;
}
.pn {
  padding-left: 0px;
  margin-left: -10px;
}
.hidden {
  visibility: hidden;
}
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 0px;
}
@media (max-width: 992px) {
  .hidden {
    display: none;
  }
  .form-row {
    display: block;
  }
  .pr-8 {
    margin-left: 8%;
  }
}
@media (min-width: 992px) {
  .pn {
    max-width: 65%;
  }
}
@media (min-width: 1200px) {
  .pn {
    max-width: 41.666667%;
  }
}
