.dropdown {
  align-self: center;
}
.leadDatePicker {
}
.filter {
  display: flex;
  z-index: 10;
  position: relative;
}
.justify {
  justify-content: space-between;
  margin: 4px;
}

.ptm-2 {
  padding-top: 0.5rem;
}
.filter1 {
  justify-content: end !important;
}
@media (max-width: 701px) {
  .filter1 {
    justify-content: start !important;
  }
}
@media (max-width: 860px) {
  .filter {
    display: block;
  }
  .filter1 {
    margin-left: 1rem;
  }
}
/* @media (min-width: 470px) {
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
} */
@media (max-width: 767.98px) {
  .PrivateHiddenCss-xsDown-10 {
    display: none;
  }
}
@media (max-width: 500px) {
  .justify {
    display: block;
  }
  .inherit {
    display: inherit !important;
  }
  .ptm-5 {
    padding-top: 3rem;
  }
  .ptm-4 {
    padding-top: 1.5rem;
  }
}
@media (max-width: 329px) {
  .nav-profile {
    padding-right: 0rem !important;
  }
  .submitLeadLop {
    margin-top: 3rem !important;
  }
}
@media (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 3rem !important;
  }
}
@media (max-width: 1025px) {
  .filter {
    display: block;
  }
  .filter1 {
    margin-left: 0.9rem;
  }
}
.MuiIconButton-edgeStart {
  margin-left: -12px;
}
