.plainText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}

.PlainTextExtra {
  color: #069aa7;
  text-decoration: none !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.HeadText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  color: #069aa7;
}

.submitLeadLop {
  background: #069aa7;
  box-shadow: 0px 4px 4px rgba(16, 156, 241, 0.24);
  border-radius: 4px;
  width: 185px;
  height: 40px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  border: none;
}

.UserName {
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  letter-spacing: 0.01em !important;
  color: #323c47 !important;
}
.userDetail {
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  letter-spacing: 0.01em !important;
  color: #707683 !important;
}
.userHead {
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  letter-spacing: 0.01em !important;
  color: #000000 !important;
  cursor: pointer;
}

.paper {
  max-height: calc(100vh - 210px) !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiInputBase-root {
  color: #069aa7 !important;
  text-decoration: none !important;
  font-family: Open Sans !important;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
}
.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInput-underline:after {
  border-bottom: none !important;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}

.lopSort {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: #6a707e;
  margin: 0;
}

.lopSortType {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #069aa7;
  cursor: pointer;
}
.MuiIconButton-edgeStart {
  margin-left: 0px !important;
}

.table-cell-single-line {
  white-space: nowrap;
}
@media (max-width: 329px) {
  .welcomeWrap {
    padding-top: 67px !important;
  }
}

@media (max-width: 320px) {
  .MuiTablePagination-toolbar {
    padding-left: 0px !important;
  }

  .MuiTablePagination-selectRoot {
    margin-right: 0px !important;
  }

  .MuiTablePagination-actions {
    margin-left: -10px !important;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .MuiTablePagination-toolbar {
    padding-left: 10px !important;
  }

  .MuiTablePagination-selectRoot {
    margin-right: 0px !important;
  }

  .MuiTablePagination-actions {
    margin-left: 0px !important;
  }
}

@media (min-width: 361px) and (max-width: 767.98px) {
  .MuiTablePagination-toolbar {
    padding-left: 10px !important;
  }

  .MuiTablePagination-selectRoot {
    margin-right: 0px !important;
  }

  .MuiTablePagination-actions {
    margin-left: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
}

@media (min-width: 1200px) {
}
col-1 {
  padding-left: 15px;
}
