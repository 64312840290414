.faqHeader {
}
.faqTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 54px;
  letter-spacing: 0.01em;
  color: #069aa7;
}
.searchFaq {
  width: 786px;
  height: 61px;
  background: #ffffff;
  border-radius: 30px;
  border: none;
  outline: none !important;
  padding-left: 60px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #90a0b7;
}

.searchWrap {
  position: relative;
}
.searchImg {
  position: absolute;
  margin-left: 30px;
  margin-top: 23px;
}

.card-header {
  background: #ffffff;
  border-radius: 4px;
  border: none;
}
.MuiTypography-body1 {
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
}
.questionText {
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
  color: #069aa7;
  border: none;
}

.MuiTreeItem-group {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #000000;
}

.MuiTreeItem-root {
  margin: 0;
  outline: 0;
  padding: 0;
  margin-bottom: 1px !important;
  padding: 20px;
  list-style: none;
  background: #ffffff;
  border-radius: 4px;
}
.MuiTreeItem-content {
  padding: 20px;
  margin-bottom: 1px;
  flex-direction: row-reverse !important;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
  outline: none;
}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
  outline: none;
}
.MuiTreeItem-label:hover {
  background-color: transparent !important;
  outline: none;
}
.MuiSvgIcon-root {
  color: black;
}

@media (max-width: 320px) {
  .faqTitle {
    font-size: 20px;
    line-height: 150%;
  }
  .searchFaq {
    width: 280px;
    height: 44px;
  }
  .searchImg {
    margin-top: 15px;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .faqTitle {
    font-size: 20px;
    line-height: 150%;
  }
  .searchFaq {
    width: 300px;
    height: 44px;
  }
  .searchImg {
    margin-top: 15px;
  }
}

@media (min-width: 361px) and (max-width: 767.98px) {
  .faqTitle {
    font-size: 20px;
    line-height: 150%;
  }
  .searchFaq {
    width: 280px;
    height: 44px;
  }
  .searchImg {
    margin-top: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .faqTitle {
    font-size: 20px;
    line-height: 150%;
  }
  .searchFaq {
    width: 500px;
    height: 51px;
  }
  .searchImg {
    margin-top: 15px;
  }
}

@media (min-width: 1200px) {
}
