.sidebar {
  width: 254px;
  height: 100vh;
  background: #ffffff;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  border-radius: 0px 80px 0px 0px;
  z-index: 10;
  margin-top: -50px;
}
/* .MuiDrawer-paper {
  background: #ffffff !important;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06) !important;
  border-radius: 0px 80px 0px 0px !important;
} */
.sideDcLogo {
  width: 134px;
  height: 45px;
  margin-top: 62px;
}

.navItems {
  width: 100%;
  height: 45px;
  padding: 10px;
  padding-left: 52px;
}

/* .navText {
  font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
letter-spacing: 0.01em;
color: #000000;

} */
