.loginText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  color: #069aa7;
}

.mobileNo {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-right: 234px;
}

.inputOtp>input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

.inputOtp>input[type=number] {
  -moz-appearance: textfield;
}

.inpBox {
  background: #069aa7;
  border-radius: 4px 0px 0px 4px;
  width: 8px;
  height: 48px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.inputOtp {
  width: 344px;
  height: 48px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  border-radius: 0px 4px 4px 0px;
  border: none;
  outline: none !important;
  padding: 15px;
}

.otpText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  /* margin-right: 40px; */
}

.sendBtn {
  background: #069aa7;
  box-shadow: 0px 4px 4px rgba(16, 156, 241, 0.24);
  border-radius: 4px;
  width: 344px;
  height: 48px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  border: none;
}

.otpText-extra {
  color: #069aa7;
  text-decoration: none !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

/* media screen */

@media (max-width: 320px) {
  .loginText {
    font-size: 24px;
  }
  .mobileNo {
    font-size: 16px;
    margin-right: 116px;
  }
  .inpBox {
    width: 2.95px;
    height: 32px;
  }
  .inputOtp {
    width: 232px;
    height: 32px;
  }
  .otpText {
    font-size: 12px;
  }
  .sendBtn {
    width: 226px;
    height: 36px;
  }
  .otpText-mob {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    padding-left: 53px;
  }
  .otpText-extra {
    padding-left: 20px;
  }
  .cardM {
    width: 300px;
    height: 585px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .loginText {
    font-size: 24px;
  }
  .mobileNo {
    font-size: 16px;
    margin-right: 116px;
  }
  .inpBox {
    width: 2.95px;
    height: 32px;
  }
  .inputOtp {
    width: 232px;
    height: 32px;
  }
  .otpText {
    font-size: 12px;
  }
  .sendBtn {
    width: 226px;
    height: 36px;
  }
  .otpText-mob {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    padding-left: 30px;
  }
  .otpText-extra {
    padding-left: 20px;
  }
 .cardM {
    width: 316px;
    height: 585px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }
}

@media (min-width: 361px) and (max-width: 767.98px) {
  .loginText {
    font-size: 24px;
  }
  .mobileNo {
    font-size: 16px;
    margin-right: 116px;
  }
  .inpBox {
    width: 2.95px;
    height: 32px;
  }
  .inputOtp {
    width: 232px;
    height: 32px;
  }
  .otpText {
    font-size: 12px;
  }
  .sendBtn {
    width: 226px;
    height: 36px;
  }
  .otpText-mob {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    padding-left: 30px;
  }
  .otpText-extra {
    padding-left: 20px;
  }
  .cardM {
    width: 316px;
    height: 585px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .loginText {
    font-size: 24px;
  }
  .mobileNo {
    font-size: 16px;
    margin-right: 116px;
  }
  .inpBox {
    width: 2.95px;
    height: 32px;
  }
  .inputOtp {
    width: 232px;
    height: 32px;
  }
  .otpText {
    font-size: 12px;
  }
  .sendBtn {
    width: 226px;
    height: 36px;
  }
  .otpText-mob {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    padding-left: 30px;
  }
  .otpText-extra {
    padding-left: 20px;
  }
 .cardM {
    width: 316px;
    height: 655px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -150px;
    padding: 20px;
  }
}



