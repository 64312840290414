.docBox {
  width: 128px;
  height: 128px;
  background: rgba(218, 208, 201, 0.3);
  border: 1px dashed rgba(114, 89, 63, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.docBox-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
}

.docBox-small {
  width: 50.49px;
  height: 14.93px;
  background: #ffffff;
  border-radius: 2px;
  margin-right: 6px;
}

.docBox-small-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 150%;
  color: #c4c4c4;
}

.docBox-img {
  height: 10px;
  width: 11px;
  padding: 1px;
}

.upload-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
}

.checkbox-terms {
  background: rgba(218, 208, 201, 0.3);
  border-radius: 4px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  border: none;
  margin-right: 14px;
}

.terms-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
  font-size: 12px;
  color: #000000;
}

.terms-text-extra {
  color: #069aa7;
  font-weight: bold;
  cursor: pointer;
}

.termsMainWeb {
  margin-right: 48px;
}

.submitBtn {
  background: #069aa7;
  box-shadow: 0px 4px 4px rgba(16, 156, 241, 0.24);
  border-radius: 4px;
  width: 466px;
  height: 48px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  border: none;
}

.modal-open .container1 {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(4px);
}

.modal-backdrop {
  background-color: gray;
}

.modal-backdrop.fade {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.modal-header {
  width: 100%;
  background: #069aa7;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.termsText {
  margin-left: 280px;
}

.close {
  color: white;
  text-shadow: none;
  opacity: 1;
}

@media (max-width: 320px) {
  .formMainDoc {
    margin-left: 80px;
  }
  .terms-text {
    font-size: 8px;
  }
  .cardSignupDoc {
    width: 320px;
    height: 850px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }
  .checkbox-terms {
    margin-right: 14px;
  }
  .termsMain {
    margin-right: 25px;
  }
  .submitBtn {
    width: 226px;
    height: 36px;
  }
  .termsText {
    margin-left: 0px;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .formMainDoc {
    margin-left: 80px;
  }
  .terms-text {
    font-size: 8px;
  }
  .cardSignupDoc {
    width: 345px;
    height: 850px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }
  .checkbox-terms {
    margin-right: 6px;
  }
  .termsMain {
    margin-right: 25px;
  }
  .submitBtn {
    width: 226px;
    height: 36px;
  }
  .termsText {
    margin-left: 0px;
  }
}

@media (min-width: 361px) and (max-width: 767.98px) {
  .formMainDoc {
    margin-left: 80px;
  }
  .terms-text {
    font-size: 8px;
  }
  .cardSignupDoc {
    width: 345px;
    height: 850px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }
  .checkbox-terms {
    margin-right: 6px;
  }
  .termsMain {
    margin-right: 25px;
  }
  .submitBtn {
    width: 226px;
    height: 36px;
  }
  .termsText {
    margin-left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .formMainDoc {
    margin-left: 165px;
  }
  .cardSignupDoc {
    width: 495px;
    height: 850px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }
  .checkbox-terms {
    margin-right: 6px;
  }
  .termsMain {
    margin-right: 25px;
  }
  .submitBtn {
    width: 226px;
    height: 36px;
  }
  .termsText {
    margin-left: 0px;
  }
}

@media (min-width: 1200px) {
  .checkBoxL {
    margin-left: 10px;
  }
}

.document-pdf-viewer-next-and-prev-button {
  border: none;
  outline: none;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.react-pdf__Page {
  display: flex !important;
  justify-content: center !important;
}

.modal, .fade ,.bd-example-modal-lg,.show{
  padding-right: 0 !important;
}

.document-pdf-viewer-page-count {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
}
.modal-head{
  position: sticky;
  top: 0;
  z-index: 1055;
}
.modal-foot{
    position: sticky;
    bottom: 0;
    z-index: 1055;
    width: 100%;
}
.bodyTnC{
  margin-left: 1rem;
  margin-top: 1rem;
}
p{
  text-align: justify;
  line-height: 1.5;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 14px;
  color:gray;
}
.tncTable{
  border:1px solid black;
  text-align: left;
  line-height: 1.5;
  font-size: 14px;
  color:gray;
  text-align: left;
}

li{
  font-size: 14px;
}
@media screen and (max-width:768px) {
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }
}