.welcomeWrap {
  padding-top: 20px;
}

.welcomeBoard {
  width: 100%;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.submitLead {
  background: #069aa7;
  box-shadow: 0px 4px 4px rgba(16, 156, 241, 0.24);
  border-radius: 4px;
  width: 400px;
  height: 56px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  border: none;
}

.welcomeText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #000000;
}

.welcomeName {
  color: #069aa7;
}

.dateText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #a3a3a3;
  margin: 0;
}

.gboxWrap {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.15));
}

.greenboxline {
  width: 8px;
  height: 160px;
  background: #069aa7;
  border-radius: 8px 0px 0px 8px;
}
.greenboxline-2 {
  width: 8px;
  /*height: 250px;*/
  background: #069aa7;
  border-radius: 8px 0px 0px 8px;
}

.greenBox {
  width: 100%;
  height: 160px;
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
  padding-top: 40px;
  padding-left: 12px;
}
.greenBox-2 {
  width: 100%;
  /*height: 250px;*/
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
  padding-top: 25px;
  padding-left: 15px;
}

.greenCircle {
  width: 64px;
  height: 63.64px;
  background: rgba(6, 154, 167, 0.2);
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.greenCircle > img {
  margin-top: 16px;
}

.totalNumber {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 1px;
  color: #069aa7;
}

.totalText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 0px;
  letter-spacing: 0.4px;
  color: #069aa7;
  padding-left: 20px;
}

.totalText2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 0px;
  letter-spacing: 0.4px;
  color: #72593f;
}
.grTotalText2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 0px;
  letter-spacing: 0.4px;
  color: #069aa7;
}
.rboxWrap {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.15));
}

.redboxline {
  width: 8px;
  height: 160px;
  background: #72593f;
  border-radius: 8px 0px 0px 8px;
}
.redboxline-2 {
  width: 8px;
  /* height: 250px;*/
  background: #72593f;
  border-radius: 8px 0px 0px 8px;
}

.redBox {
  width: 100%;
  height: 160px;
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
  padding-top: 40px;
  padding-left: 12px;
}
.redBox-2 {
  width: 100%;
  /*height: 250px;*/
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
  padding-top: 25px;
  padding-left: 15px;
}
.redBox-2-2 {
  width: 100%;
  /* height: 238px; */
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
  padding-top: 25px;
  padding-left: 15px;
}

.redCircle {
  width: 64px;
  height: 63.64px;
  background: rgba(114, 89, 63, 0.2);
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.redCircle2 {
  width: 63px;
  height: 64px;
  background: rgba(114, 89, 63, 0.2);
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  padding-top: 12px;
}

.redCircle > img {
  margin-top: 13px;
}
.gboxWrap:hover,
.rboxWrap:hover {
  transform: scale(1.02);
  transition: all 0.4s;
}

.rTotalNumber {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 1px;
  color: #72593f;
}

.rTotalText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 0px;
  letter-spacing: 0.4px;
  color: #72593f;
}

.grTotalNumber {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 1px;
  color: #069aa7;
}

.grTotalText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 0px;
  letter-spacing: 0.4px;
  color: #069aa7;
}

.greenboxlineLarge {
  width: 8px;
  height: 200px;
  background: #069aa7;
  border-radius: 8px 0px 0px 8px;
}

.greenBoxLarge {
  width: 100%;
  height: 200px;
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
}

.redboxlineLarge {
  width: 8px;
  height: 200px;
  background: #72593f;
  border-radius: 8px 0px 0px 8px;
}

.redBoxLarge {
  width: 100%;
  height: 200px;
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
}

.numberTotalLarge {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 1px;
  color: #069aa7;
}

.numberTotalTextLarge {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #069aa7;
}

.redNumberTotalLarge {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  letter-spacing: 1px;
  color: #72593f;
}

.redNumberTotalTextLarge {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #72593f;
}

.leadsGraph {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
}

.leadsGraphText {
  height: 35px;
  background: #069aa7;
  border-radius: 8px 8px 0px 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.dateInput {
  border: none;
  background: transparent;
  border-bottom: 2px solid #069aa7;
  outline: none;
  width: 100px;
}

.funnelChart {
  padding: 20px;
}

._1WnkD ._1JWHC ._3tM6f {
  font-size: 10px !important;
}
ul {
  padding-inline-start: 30px;
  font-weight: 500;
  margin-top: 5px;
}
li {
  color: gray;
  font-size: 16px;
}

.gboxWrap,
.rboxWrap {
  cursor: pointer;
}

@media (max-width: 320px) {
  .welcomeBoard {
    width: 280px;
    height: 142px;
  }
  .submitLead {
    width: 200px;
    height: 36px;
    font-size: 16px;
  }
  .welcomeText {
    font-size: 16px;
  }
  .mobileCenter {
    margin: 0 auto;
    width: 80%;
  }

  .mobileTop {
    margin-top: 200px;
  }
  .totalNumber {
    font-size: 30px;
  }
  .rTotalNumber {
    font-size: 30px;
  }
  /* .greenboxline {
    width: 5px;
    height: 100px;
  }
  .greenBox {
    width: 140px;
    height: 102px;
  }
  .greenCircle {
    width: 40px;
    height: 40px;
  }
  .greenCircle > img {
    margin-top: 16px;
  }
  .totalNumber {
    font-size: 24px;
  }
  .totalText {
    font-size: 12px;
  }
  .totalText2 {
    font-size: 12px;
  }

  .redboxline {
    width: 5px;
    height: 101px;
  }
  .redBox {
    width: 140px;
    height: 102px;
    padding-top: 40px;
    padding-left: 20px;
  }
  .redCircle {
    width: 50px;
    height: 40px;
  }
  .redCircle2 {
    width: 40px;
    height: 40px;
  }
  .redCircle > img {
    margin-top: 13px;
  }

  .rTotalNumber {
    font-size: 24px;
  }
  .rTotalText {
    font-size: 12px;
  } */
  .greenboxlineLarge {
    width: 5px;
    height: 79px;
  }
  .greenBoxLarge {
    width: 100%;
    height: 79px;
  }
  .redboxlineLarge {
    width: 5px;
    height: 79px;
  }
  .redBoxLarge {
    width: 100%;
    height: 79px;
  }
  .numberTotalLarge {
    font-size: 16px;
    line-height: 0px;
  }
  .numberTotalTextLarge {
    font-size: 7px;
    line-height: 0px;
  }
  .redNumberTotalLarge {
    font-size: 24px;
    margin: 0px;
  }
  .redNumberTotalTextLarge {
    font-size: 12px;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .welcomeBoard {
    width: 300px;
    height: 142px;
  }
  .submitLead {
    width: 226px;
    height: 36px;
    font-size: 16px;
  }
  .welcomeText {
    font-size: 16px;
  }
  .mobileCenter {
    margin: 0 auto;
    width: 80%;
  }

  .mobileTop {
    margin-top: 200px;
  }
  .totalNumber {
    font-size: 26px;
  }
  .rTotalNumber {
    font-size: 26px;
  }
  .greenboxlineLarge {
    width: 5px;
    height: 79px;
  }
  .greenBoxLarge {
    width: 100%;
    height: 79px;
  }
  .redboxlineLarge {
    width: 5px;
    height: 79px;
  }
  .redBoxLarge {
    width: 100%;
    height: 79px;
  }
  .numberTotalLarge {
    font-size: 16px;
    line-height: 0px;
  }
  .numberTotalTextLarge {
    font-size: 9px;
    line-height: 0px;
  }
  .redNumberTotalLarge {
    font-size: 24px;
    margin: 0px;
  }
  .redNumberTotalTextLarge {
    font-size: 12px;
  }
}
@media (min-width: 1200px) {
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 19.99%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 19.99%;
  }
  .col-xl-2 {
    max-width: 24.99%;
  }
  .col-xl-4 {
  }
}
@media (min-width: 361px) and (max-width: 767.98px) {
  .welcomeBoard {
    height: 142px;
  }
  .submitLead {
    width: 226px;
    height: 36px;
    font-size: 16px;
  }
  .welcomeText {
    font-size: 16px;
  }
  .mobileCenter {
    margin: 0 auto;
    width: 80%;
  }

  .totalNumber {
    font-size: 26px;
  }
  .rTotalNumber {
    font-size: 26px;
  }
  .greenboxlineLarge {
    width: 5px;
    height: 79px;
  }
  .greenBoxLarge {
    width: 100%;
    height: 79px;
  }
  .redboxlineLarge {
    width: 5px;
    height: 79px;
  }
  .redBoxLarge {
    width: 100%;
    height: 79px;
  }
  .numberTotalLarge {
    font-size: 16px;
    line-height: 0px;
  }
  .numberTotalTextLarge {
    font-size: 9px;
    line-height: 0px;
  }
  .redNumberTotalLarge {
    font-size: 24px;
    margin: 0px;
  }
  .redNumberTotalTextLarge {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .welcomeBoard {
    height: 142px;
  }
  .submitLead {
    width: 226px;
    height: 36px;
    font-size: 16px;
  }
  .welcomeText {
    font-size: 16px;
  }
  .totalNumber {
    font-size: 26px;
  }
  .rTotalNumber {
    font-size: 26px;
  }
  .greenboxlineLarge {
    width: 5px;
    height: 79px;
  }
  .greenBoxLarge {
    width: 100%;
    height: 79px;
  }
  .redboxlineLarge {
    width: 5px;
    height: 79px;
  }
  .redBoxLarge {
    width: 100%;
    height: 79px;
  }
  .numberTotalLarge {
    font-size: 18px;
    line-height: 0px;
  }
  .numberTotalTextLarge {
    font-size: 14px;
    line-height: 0px;
  }
  .redNumberTotalLarge {
    font-size: 24px;
    margin: 0px;
  }
  .redNumberTotalTextLarge {
    font-size: 12px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .submitLead {
    width: 226px;
    height: 36px;
    font-size: 16px;
  }
  .welcomeText {
    font-size: 16px;
  }
}
@media (min-width: 361px) and (max-width: 575px) {
  .mobileTop {
    margin-top: 200px;
  }
}

.dashboard-all-time-button {
  background: #069aa7 !important;
  box-shadow: 0px 4px 4px rgba(16, 156, 241, 0.24);
  border-radius: 4px;
  color: #ffffff !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  width: 80px;
  border: none;
  height: 30px;
  font-size: 13px;
  text-transform: none !important;
}
.apexcharts-toolbar {
  display: none !important;
}
