.profileName {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #000000;
  padding-top: 12px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.headerSearch {
  width: 786px;
  height: 50px;
  background: #ffffff;
  border: none;
  outline: none !important;
  padding-left: 60px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchImgHeader {
  position: absolute;
  margin-left: 30px;
  margin-top: 18px;
}

.popupImg {
  margin-top: 10px;
  margin-left: 5px;
}

.popupUserName {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #069aa7;
}

.popupText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.nav-profile {
  padding-right: 4rem ;
}
@media (max-width: 320px) {
}

@media (min-width: 321px) and (max-width: 360px) {
}

@media (min-width: 361px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 1023px) {
}

@media (min-width: 1200px) {
}
