.projectStatus {
  width: 66px;
  height: 25px;
  background: #5fdba7;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 3px;
  color: #ffffff;
}

.projectStatusOnHold {
  width: 66px;
  height: 25px;
  background: #f26565;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 3px;
  color: #ffffff;
}

.projectStatusCompleted {
  width: 90px;
  height: 25px;
  background: #000000;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 3px;
  color: #ffffff;
}

.projectStage {
  width: fit-content !important;
  height: auto !important;
  background: #5fdba7;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 3px;
  white-space: nowrap;
  color: #ffffff;
}

.projectStageDispatch {
  width: 116px;
  height: 25px;
  background: #df8c35;
  border-radius: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 3px;
  color: #ffffff;
}