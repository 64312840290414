.loginImg {
  width: 100%;
  height: 100vh;
}

@media (max-width: 320px) {
  .loginImg {
    width: 100vw;
    height: 280px;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .loginImg {
    width: 100vw;
    height: 320px;
  }
}

@media (min-width: 361px) and (max-width: 767.98px) {
  .loginImg {
    width: 100vw;
    height: 320px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .loginImg {
    width: 100vw;
    height: 450px;
  }
}

@media (min-width: 1200px) {
  .loginImg {
    width: 100%;
    height: 100vh;
  }
}

.backDrop {
  position: absolute;
  z-index: 10;
}