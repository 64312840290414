.signupText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}

.infoBar {
  width: 224px;
  height: 4px;
  background: #069aa7;
  border-radius: 4px;
}

.signupText-blur {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #c4c4c4;
}

.infoBar-blur {
  width: 224px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 4px;
}

.inputLabel {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  /* margin-right: 234px; */
}

.inputSignup {
  width: 224px;
  height: 48px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  border-radius: 0px 4px 4px 0px;
  border: none;
  outline: none !important;
  padding-left: 10px;
}

.verifyNum {
  background: #069aa7;
  box-shadow: 0px 4px 4px rgba(16, 156, 241, 0.24);
  border-radius: 4px;
  width: 466px;
  height: 48px;
  color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  border: none;
}

.mob {
  margin-right: 130px;
}

@media (max-width: 320px) {
  .cardSignup {
    width: 320px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }

  .signupText {
    font-size: 12px;
  }
  .infoBar {
    width: 149px;
  }
  .signupText-blur {
    font-size: 12px;
  }

  .infoBar-blur {
    width: 149px;
  }
  .formMain {
    margin-left: 0px;
  }
  .inputSignup {
    width: 232px;
    height: 32px;
  }
  .otpCenter {
    margin-left: 80px;
  }
  .verifyNum {
    width: 226px;
    height: 36px;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .cardSignup {
    width: 345px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }
  .signupText {
    font-size: 12px;
  }
  .infoBar {
    width: 149px;
  }
  .signupText-blur {
    font-size: 12px;
  }
  .infoBar-blur {
    width: 149px;
  }
  .formMain {
    margin-left: 0px;
  }
  .inputSignup {
    width: 232px;
    height: 32px;
  }
  .otpCenter {
    margin-left: 31px;
  }
  .verifyNum {
    width: 226px;
    height: 36px;
  }
}

@media (min-width: 361px) and (max-width: 767.98px) {
  .cardSignup {
    width: 345px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }
  .signupText {
    font-size: 12px;
  }
  .infoBar {
    width: 149px;
  }
  .signupText-blur {
    font-size: 12px;
  }
  .infoBar-blur {
    width: 149px;
  }
  .formMain {
    margin-left: 0px;
  }
  .inputSignup {
    width: 232px;
    height: 32px;
  }
  .otpCenter {
    margin-left: 31px;
  }
  .verifyNum {
    width: 226px;
    height: 36px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cardSignup {
    width: 345px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: none;
    margin-top: -80px;
    padding: 20px;
  }
  .signupText {
    font-size: 12px;
  }
  .infoBar {
    width: 149px;
  }
  .signupText-blur {
    font-size: 12px;
  }
  .infoBar-blur {
    width: 149px;
  }
  .formMain {
    margin-left: 0px;
  }
  .inputSignup {
    width: 232px;
    height: 32px;
  }
  .otpCenter {
    margin-left: 31px;
  }
  .verifyNum {
    width: 226px;
    height: 36px;
  }
}

@media (min-width: 1200px) {
}
