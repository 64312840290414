.resend {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #069aa7;
  cursor: pointer;
}

@media (max-width: 320px) {
  .resend {
    font-size: 12px;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .resend {
    font-size: 12px;
  }
}

@media (min-width: 361px) and (max-width: 767.98px) {
  .resend {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
}

@media (min-width: 1200px) {
}
