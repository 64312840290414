 .activeNav {
    background: #069AA7;
    box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
    color:white !important;
}
.norma {
   display: block;
   width: 100%;
   height: 45px;
   padding: 10px;
   padding-left: 52px;
   font-family: Open Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 22px;
   letter-spacing: 0.01em;
   color: #000000;
}

.norma:hover {
    text-decoration: none !important;
    color:#000000;
}